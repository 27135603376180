export const environment = {
    production: true,
    envName: "fromTFS",
    clientID: "f0b5e33c-4a0f-4f65-a355-94da8084f764",
    urlCloudApi: "https://devaccount.ansys.com/",
    authority: "https://login.microsoftonline.com/tfp/a365dev.onmicrosoft.com/B2C_1_AccountAdmin_sign_in_dev",
    adminGroupId: "375184be-7f1e-4dc2-88a7-5bd245c3afd6",
    oidc: "https://devaccountregistration.ansys.com",
    oidcClientId: "portal",
    appInsights: { instrumentationKey: "00e20d97-16f5-4c9d-af94-94d01d5cde8e" },
    enableAnsysOnline: true,
};
